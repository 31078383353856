import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Experience from './experience'

const Timelines = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0.31rem;
    bottom: 0.31rem;
    width: 0.18rem;
    background: #9DB9D6;
    left: 11%;
    margin-left: -0.15rem;
    @media (max-width: 700px) {
      display: none;
    }
  }
`

const Experiences = (props) => {  
  const {
    experiences
  } = props;


  return (
    <Timelines> 
      { experiences && experiences.map((experience, index) => {
         return <Experience key={`exp-${index}`} experience={experience} />
        })
      }
    </Timelines>
      )
}

export default Experiences