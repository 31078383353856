import React, {useState, useEffect} from "react"
import styled from "styled-components"
import JSONTree from 'react-json-tree';
import ObjectInspector from 'react-object-inspector';


const StyledContainer = styled.form`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1rem;
  font-size: 0.75rem;
  color: #718096
`

const StyledProfileHeader = styled.div`
  color: #1A212D;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .info {
    width: 100%;
    padding: 1rem;
    border: 1px solid #CBD5E0;
    border-radius: 0.32rem 0.32rem 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      display: flex;
      flex: 1 1 50%;
      max-width: 50%;
      min-width: 300px;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        min-width: 100%;
      }
    }
    .right {
      flex: 1 1 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: unset;

      div {
        display: flex;
        justify-content: flex-start;
        span {
          margin-right: 0.5rem;
          text-align: end;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      @media (min-width: 500px) {
        margin-top: 0;
        flex: 1 1 45%;
        max-width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 300px;
        div {
          justify-content: flex-end;
        }
      }
    }
    background: #F8FBFF;
    .img {
      height: 5rem;
      width: 5rem;
      min-width: 5rem;
      border-radius: 50%;
      border: 1px solid #CBD5E0;
    }
    .content {
      height: 5rem;
      padding: 1rem;
      @media (max-width: 400px) {
        padding: 1rem 0;
        height: unset;
        width: 100%;
        justify-self: left;
      }
      .name {
        font-weight: 500;
        font-size: 1rem;
        color: #1A212D;
        line-height: 1.25rem;
      }
      .summary {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
      }
    }
  }
  .skills {
    width: 100%;
    padding: 1rem;
    border: 1px solid #CBD5E0;
    border-top: none;
    border-radius: 0;
  }
  .languages {
    border: 1px solid #CBD5E0;
    border-top: none;
    display: flex;
    width: 100%;
    border-radius: 0 0 0.32rem 0.32rem;
    .left {
      border-right: 1px solid #CBD5E0;
      padding: 1rem;
      border-radius: 0 0 0 0.32rem;
      flex: 1 1 50%;
    }
    .right {
      padding: 1rem;
      border-radius: 0 0  0.32rem 0;      
      flex: 1 1 50%;
    }
  }

  .label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: #138388;
  }

  .summary {
    white-space: pre-wrap;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .skills.tasks {
    ul {
      list-style: disc;
      margin-left: 1rem;
      padding: 0;
    }
  }
`

const Tag = styled.li`
  color: #1DAFB6;
  padding: 0.25rem 0.75rem;
  border-image: initial;
  border: 1px solid #1DAFB6;
  border-radius: 3px;
  font-size: 0.75rem;
  margin: 6px;
  background: #EAFEFF;
  &:first-of-type {
    margin-left: 0;
  }
`

const StyledProfileBody = styled.div`
  border: 1px solid #CBD5E0;
  border-radius: 0.3rem;
  padding: 1rem;
  background: #F8FBFF;
  position: relative;
  width: 100%;
  overflow: auto;
  .label {
    font-weight: bold;
    margin: 1rem 0;
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: #138388;
  }
  .copy {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
`


const Debugger = ({ profile }) => {
  console.log('profile', profile)
  const [copy, setCopy] = useState(false)


  const copyLink = (e, url) => {
    e.stopPropagation();
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.setAttribute('value', url);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    setCopy(true);
    setTimeout(() => setCopy(false), 2000)
  }

  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  };
  const json = {
    array: [1, 2, 3],
    bool: true,
    object: {
      foo: 'bar',
    }
  };
  return (
    <StyledContainer color="#22B5F3">
      <StyledProfileHeader>
        JSON Result
      </StyledProfileHeader>
      <StyledProfileBody>
        <span
          role="button"
          className="copy"
          onClick={e => copyLink(e, JSON.stringify(profile))}
        >
          {copy ? 'Copied' : 'Copy'}
        </span>
         <ObjectInspector
          data={ profile }
          initialExpandedPaths={['root']}
        />
      </StyledProfileBody>
    </StyledContainer>
  )
}


export default Debugger
