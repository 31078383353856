import React from "react"
import PropTypes from "prop-types"

const Phone = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M0.5 1.25C0.5 1.05109 0.579018 0.860322 0.71967 0.71967C0.860322 0.579018 1.05109 0.5 1.25 0.5H2.86475C3.04228 0.500082 3.21402 0.563136 3.34943 0.677945C3.48484 0.792754 3.57514 0.951874 3.60425 1.127L4.15925 4.45325C4.18577 4.61166 4.16073 4.77441 4.08782 4.91752C4.01491 5.06064 3.89799 5.17657 3.75425 5.24825L2.59325 5.828C3.00958 6.85974 3.62963 7.79696 4.41633 8.58367C5.20304 9.37038 6.14026 9.99042 7.172 10.4067L7.7525 9.24575C7.82414 9.10215 7.93995 8.98532 8.08291 8.91242C8.22588 8.83952 8.38845 8.8144 8.54675 8.84075L11.873 9.39575C12.0481 9.42486 12.2072 9.51516 12.3221 9.65057C12.4369 9.78598 12.4999 9.95772 12.5 10.1353V11.75C12.5 11.9489 12.421 12.1397 12.2803 12.2803C12.1397 12.421 11.9489 12.5 11.75 12.5H10.25C4.865 12.5 0.5 8.135 0.5 2.75V1.25Z" fill={color} />
    </svg>
  )
}

Phone.propTypes = {
  color: PropTypes.string,
}

Phone.defaultProps = {
  color: "#000000",
}

export default Phone