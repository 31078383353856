import React from "react"
import PropTypes from "prop-types"

const Email = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="15px"
      height="15px"
      viewBox="0 0 15 15"
    >
      <path d="M1.50244 4.413L7.50019 7.4115L13.4979 4.413C13.4757 4.03083 13.3083 3.67162 13.0298 3.40892C12.7514 3.14622 12.383 2.99993 12.0002 3H3.00019C2.61738 2.99993 2.24903 3.14622 1.97057 3.40892C1.69212 3.67162 1.52464 4.03083 1.50244 4.413Z" fill={color} />
      <path d="M13.5 6.08789L7.5 9.08789L1.5 6.08789V10.4994C1.5 10.8972 1.65804 11.2787 1.93934 11.5601C2.22064 11.8414 2.60218 11.9994 3 11.9994H12C12.3978 11.9994 12.7794 11.8414 13.0607 11.5601C13.342 11.2787 13.5 10.8972 13.5 10.4994V6.08789Z" fill={color} />
    </svg>
  )
}

Email.propTypes = {
  color: PropTypes.string,
}

Email.defaultProps = {
  color: "#000000",
}

export default Email