import React, {useEffect} from "react"
import styled from "styled-components"
import Spinner from 'components/common/spinner'
import Parsing from './parsing'
import Debugger from './debugger'

import ErrorMessage from "components/common/errorMessage"

const StyledContainer = styled.div`
  background: white;
  min-height: 20rem;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  margin-bottom: 2rem;
  .empty {
    height: 17rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Result = (props) => {
  useEffect(() => {
    const email = props.email && props.email.replace(/\s+/g, '+').toLowerCase();
    props.getProfileIndexing({profile_key: props.profileKey, email: email})
    // scrollTo('#result')
  },[])

  if (props.profileIndexing.r) {
    return (
      <StyledContainer color="#22B5F3">
        <Spinner />
      </StyledContainer>
    )
  } else if (props.profileIndexing.s && props.profileIndexing.payload) {
    return (
      <>
        <StyledContainer color="#22B5F3">
          <Parsing profile={props.profileIndexing.payload} />
        </StyledContainer>
        <StyledContainer color="#22B5F3">
          <Debugger profile={props.profileIndexing.payload} />
        </StyledContainer>
      </>
    )
  } else {
    return (
      <StyledContainer color="#22B5F3">
        <div className="empty">
          <ErrorMessage
            title="Something went wrong"
            subtitle="Please retry again later."
            type="error"
            min="true"
          />
        </div>
      </StyledContainer>
    )
  }
}

export default Result;