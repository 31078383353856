import React from "react"
import PropTypes from "prop-types"

const Home = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M7.03031 0.71959C6.88967 0.578986 6.69894 0.5 6.50006 0.5C6.30119 0.5 6.11046 0.578986 5.96981 0.71959L0.719811 5.96959C0.583193 6.11104 0.507597 6.30049 0.509306 6.49714C0.511015 6.69379 0.589891 6.8819 0.728947 7.02095C0.868003 7.16001 1.05611 7.23889 1.25276 7.24059C1.44941 7.2423 1.63886 7.16671 1.78031 7.03009L2.00006 6.81034V11.7498C2.00006 11.9488 2.07908 12.1395 2.21973 12.2802C2.36038 12.4208 2.55115 12.4998 2.75006 12.4998H4.25006C4.44897 12.4998 4.63974 12.4208 4.78039 12.2802C4.92104 12.1395 5.00006 11.9488 5.00006 11.7498V10.2498C5.00006 10.0509 5.07908 9.86016 5.21973 9.71951C5.36038 9.57886 5.55115 9.49984 5.75006 9.49984H7.25006C7.44897 9.49984 7.63974 9.57886 7.78039 9.71951C7.92104 9.86016 8.00006 10.0509 8.00006 10.2498V11.7498C8.00006 11.9488 8.07908 12.1395 8.21973 12.2802C8.36038 12.4208 8.55115 12.4998 8.75006 12.4998H10.2501C10.449 12.4998 10.6397 12.4208 10.7804 12.2802C10.921 12.1395 11.0001 11.9488 11.0001 11.7498V6.81034L11.2198 7.03009C11.3613 7.16671 11.5507 7.2423 11.7474 7.24059C11.944 7.23889 12.1321 7.16001 12.2712 7.02095C12.4102 6.8819 12.4891 6.69379 12.4908 6.49714C12.4925 6.30049 12.4169 6.11104 12.2803 5.96959L7.03031 0.71959Z" fill={color}/>
    </svg>
  )
}

Home.propTypes = {
  color: PropTypes.string,
}

Home.defaultProps = {
  color: "#000000",
}

export default Home