import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Education from './education'

const Timelines = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0.31rem;
    bottom: 0.31rem;
    width: 0.18rem;
    background: rgba(67, 90, 111, 0.3);
    left: 11%;
    margin-left: -0.15rem;
    @media (max-width: 700px) {
      display: none;
    }
  }
`

const Educations = (props) => {  
  const {
    educations
  } = props;


  return (
    <Timelines>
      { educations && educations.map((education, index) => {
         return <Education key={`exp-${index}`} education={education} />
        })
      }
    </Timelines>
      )
}

export default Educations