import React, {useState, useEffect} from "react"
import styled from "styled-components"
import avatar from 'content/images/avatar.jpg'
import Email from "components/icons/email"
import Home from "components/icons/home"
import Phone from "components/icons/phone"
import Experiences from './experiences'
import Educations from './educations'

const StyledContainer = styled.form`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1rem;
  font-size: 0.75rem;
  color: #718096
`

const StyledSectionTitle = styled.div`
  color: #1A212D;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
`

const StyledProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .info {
    width: 100%;
    padding: 1rem;
    border: 1px solid #CBD5E0;
    border-radius: 0.32rem 0.32rem 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      display: flex;
      flex: 1 1 50%;
      max-width: 50%;
      min-width: 300px;
      @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        min-width: 100%;
      }
    }
    .right {
      flex: 1 1 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: unset;

      div {
        display: flex;
        justify-content: flex-start;
        span {
          margin-right: 0.5rem;
          text-align: end;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
      @media (min-width: 500px) {
        margin-top: 0;
        flex: 1 1 45%;
        max-width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 300px;
        div {
          justify-content: flex-end;
        }
      }
    }
    background: #F8FBFF;
    .img {
      height: 5rem;
      width: 5rem;
      min-width: 5rem;
      border-radius: 50%;
      border: 1px solid #CBD5E0;
    }
    .content {
      height: 5rem;
      padding: 1rem;
      @media (max-width: 400px) {
        padding: 1rem 0;
        height: unset;
        width: 100%;
        justify-self: left;
      }
      .name {
        font-weight: 500;
        font-size: 1rem;
        color: #1A212D;
        line-height: 1.25rem;
      }
      .summary {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
      }
    }
  }
  .skills {
    width: 100%;
    padding: 1rem;
    border: 1px solid #CBD5E0;
    border-top: none;
    border-radius: 0;
  }
  .languages {
    border: 1px solid #CBD5E0;
    border-top: none;
    display: flex;
    width: 100%;
    border-radius: 0 0 0.32rem 0.32rem;
    .left {
      border-right: 1px solid #CBD5E0;
      padding: 1rem;
      border-radius: 0 0 0 0.32rem;
      flex: 1 1 50%;
    }
    .right {
      padding: 1rem;
      border-radius: 0 0  0.32rem 0;      
      flex: 1 1 50%;
    }
  }

  .label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: #138388;
  }

  .summary {
    white-space: pre-wrap;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .skills.tasks {
    ul {
      display: block;
      list-style: disc;
      margin-left: 1rem;
      padding: 0;
    }
  }
`

const Tag = styled.li`
  color: #1DAFB6;
  padding: 0.25rem 0.75rem;
  border-image: initial;
  border: 1px solid #1DAFB6;
  border-radius: 3px;
  font-size: 0.75rem;
  margin: 6px;
  background: #EAFEFF;
  &:first-of-type {
    margin-left: 0;
  }
`

const StyledProfileBody = styled.div`
  width: 100%;
  overflow: auto;
  .label {
    font-weight: bold;
    margin: 1rem 0;
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: #138388;
  }
`
const Parsing = ({ profile }) => {
  console.log('profile', profile)
  return (
    <StyledContainer color="#22B5F3">
      <StyledSectionTitle>Visual Result</StyledSectionTitle>
      <StyledProfileHeader>
        <div className="info">
          <div className="left">
            <img
              src={profile.info.picture || avatar}
              alt="profile photo"
              className="img"
            />
            <div className="content">
              <div className="name">{profile.info.full_name ? profile.info.full_name : "_"}</div>
            </div>
          </div>
          <div className="right">
            { profile.info.email && (
              <div className="mb-05">
                <span>{profile.info.email}</span><Email color="#1DAFB6" />
              </div>
            )}
            { profile.info.phone && (
              <div className="mb-05">
                <span>{profile.info.phone}</span><Phone color="#1DAFB6" />
              </div>
            )}
            { profile.info.location.text && (
              <div>
                <span>{profile.info.location.text}</span><Home color="#1DAFB6" />
              </div>
            )}
          </div>
        </div>
        <div className="skills">
          <div className="label">
            Summary:
          </div>
          <div className="summary">
            {profile.info.summary}
          </div>
        </div>
        {profile.skills && profile.skills.length > 0 && (
          <div className="languages">
            <div className="left">
              <div className="label">
                Hard Skills:
              </div>
              <ul>
                { profile.skills && profile.skills.filter(skill => skill.type === "hard").map((skill, index) => {
                    return <Tag key={`hard-${index}`}>{skill.name}</Tag>
                  })
                }
              </ul>
            </div>
            <div className="right">
              <div className="label">
                Soft Skills:
              </div>
              <ul>
                { profile.skills && profile.skills.filter(skill => skill.type === "soft").map((skill, index) => {
                    return <Tag key={`soft-${index}`}>{skill.name}</Tag>
                  })
                }
              </ul>
            </div>
          </div>
        )}
        {profile.tasks && profile.tasks.length > 0 && (
          <div className="skills tasks">
            <div className="label">
              Tasks:
            </div>
            <ul>
              { profile.tasks && profile.tasks.map((task, index) => {
                  return <li key={`task-${index}`}>{task.name}</li>
                })
              }
            </ul>
          </div>
        )}
        {((profile.certifications && profile.certifications.length > 0)  || (profile.courses && profile.courses.length > 0)) && (
          <div className="languages">
            {profile.certifications && profile.certifications.length > 0 && (
              <div className="left">
                <div className="label">
                  Certifications:
                </div>
                <ul>
                  {profile.certifications.map((certification, index) => {
                      return <Tag key={`certification-${index}`}>{certification.name}</Tag>
                    })
                  }
                </ul>
              </div>
            )}
            {profile.courses && profile.courses.length > 0 && (
              <div className="right">
                <div className="label">
                  Courses:
                </div>
                <ul>
                  {profile.courses && profile.courses.map((course, index) => {
                      return <Tag key={`course-${index}`}>{course.name}</Tag>
                    })
                  }
                </ul>
              </div>
            )}
          </div>
        )}
        {(profile.languages && profile.languages.length > 0 || profile.interests  && profile.interests.length > 0) && (
          <div className="languages">
            {profile.languages && profile.languages.length > 0 && (
              <div className="left">
                <div className="label">
                  Languages:
                </div>
                <ul>
                  {profile.languages && profile.languages.map((lang, index) => {
                      return <Tag key={`lang-${index}`}>{lang.name}</Tag>
                    })
                  }
                </ul>
              </div>
            )}
            {profile.interests && profile.interests.length && (
              <div className="right">
                <div className="label">
                  Interests:
                </div>
                <ul>
                  {profile.interests && profile.interests.map((int, index) => {
                      return <Tag key={`int-${index}`}>{int.name}</Tag>
                    })
                  }
                </ul>
              </div>
            )}
          </div>
        )}
      </StyledProfileHeader>
      <StyledProfileBody>
        <div className="label">
          Experiences
        </div>
        <Experiences experiences={profile.experiences}/>
        <div className="label">
          Educations
        </div>
        <Educations educations={profile.educations}/>
      </StyledProfileBody>
    </StyledContainer>
  )
}


export default Parsing
