import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import moment from 'moment';


const Timeline = styled.div`
  position: relative;
  min-height: 3.12rem;
  padding: 1.25rem 0;

  .timeline-time {
    position: absolute;
    left: 0;
    width: 18%;
    text-align: left;
    top: 1.87rem;
    .date {
      line-height: 1rem;
      font-size: 0.9rem;
      display: block;
      font-weight: 600;
      color: #9DB9D6;
    }
    .year {
      line-height: 1.4rem;
      font-size: 1.1rem;
      font-weight: 700;
      color: #9DB9D6;
      opacity: 0.7;

    }
  }
  .timeline-icon {
    left: 5.91%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 2.5rem;
    @media (max-width: 700px) {
      display: none;
    }
    a {
      text-decoration: none;
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      border-radius: 1.25rem;
      background: #f7f8fa;
      line-height: 1.1rem;
      color: #fff;
      font-size: 0.9rem;
      border: 3px solid #9DB9D6;
      transition: border-color .2s linear;
    }
  }
  .timeline-body {
    margin-left: 16%;
    with: 84%;
    max-width: 84%;
    overflow: hidden;
    position: relative;
    @media (max-width: 700px) {
      display: none;
      margin-left: 0;
    }
  }

  .item__box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.3rem;
    background: white;
    box-sizing: border-box;
    margin-bottom: 2rem;
    border: 1px solid #CBD5E0;
    @media (max-width: 700px) {
      margin-bottom: 0;
    }
    .item__box-header {
      padding: 1rem;
      background: #F8FBFF;
      border-radius: 0.32rem 0.32rem 0 0;
      line-height: 1rem;
      font-size: 0.75rem;
      color: #718096;
      display: flex;
      flex-direction: column;
      width: 100%;
      .left {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.4rem;
        .company {
          color: #1DAFB6;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
        .title {
          color: #1A212D;
        }
      }
      .right {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .location {
          margin-bottom: 0.7rem;
        }
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;
        .left {
          flex: 1 1 50%;
        }
        .right {
          flex: 1 1 50%;
          align-items: flex-end;
        }
      }
    }

    .item__box-footer {
      border-radius: 0 0 0.32rem 0.32rem;
      padding: 1rem;
      line-height: 1rem;
      font-size: 0.8rem;
      color: #183D63;
      border-top: 1px solid #CBD5E0;
      width: 100%;
      white-space: pre-wrap;
    }
  }
  .skills {
    width: 100%;
    padding: 1rem;
    border-top: 1px solid #CBD5E0;
    border-radius: 0;
  }
  .languages {
    border-top: 1px solid #CBD5E0;
    display: flex;
    width: 100%;
    border-radius: 0 0 0.32rem 0.32rem;
    .left {
      border-right: 1px solid #CBD5E0;
      padding: 1rem;
      border-radius: 0 0 0 0.32rem;
      flex: 1 1 50%;
    }
    .right {
      padding: 1rem;
      border-radius: 0 0  0.32rem 0;      
      flex: 1 1 50%;
    }
  }

  .label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    line-height: 1.1rem;
    color: #138388;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .skills.tasks {
    ul {
      list-style: disc;
      margin-left: 1rem;
      padding: 0;
      display: block;
    }
  }
`

const Tag = styled.li`
  color: #1DAFB6;
  padding: 0.25rem 0.75rem;
  border-image: initial;
  border: 1px solid #1DAFB6;
  border-radius: 3px;
  font-size: 0.75rem;
  margin: 6px;
  background: #EAFEFF;
  &:first-of-type {
    margin-left: 0;
  }
`

const validateDate = date => {
  if (moment(date).format('DD/MM/YYYY') !== 'Invalid date') {
    return moment(date).format('MMM. YYYY')
  } else {
    return '';
  };
}

const Education = (props) => {  

  const { education } = props;
  const title = education.title;
  const school = education.school;
  const startDate = education.date_start; 
  const endDate = education.date_end; 
  const location = education.location.text;
  const description = education.description;

  return (
    <Timeline>
      {startDate && (
        <div className="timeline-time">
          <span className="date">{moment(startDate).format('DD MMM')}</span>
          <span className="year">{moment(startDate).year()}</span>
        </div>
      )}
      <div className="timeline-icon">
          <a href="javascript:;">&nbsp;</a>
      </div>
      <div className="item__box timeline-body">
        <div className="item__box-header">
          <div className="left">
            <span className="company">{school || '_'}</span>
            <span className="title">{title || '_'}</span>
          </div>
          <div className="right">
            {location && (
              <span className="location">
                {location}
              </span>
            )}
            <span className="date">
              {validateDate(startDate) ? 'From' + ' ' + validateDate(startDate) : ''}
              {validateDate(endDate) ? ' ' + 'to' + ' ' + validateDate(endDate) : ''}
            </span>
          </div>
        </div>
        <div className="item__box-footer">
          {description}
        </div>
        <div className="languages">
          <div className="left">
            <div className="label">
              Hard Skills:
            </div>
            <ul>
              { education.skills && education.skills.filter(skill => skill.type === "hard").map((skill, index) => {
                  return <Tag key={`hard-${index}`}>{skill.name}</Tag>
                })
              }
            </ul>
        </div>
        <div className="right">
          <div className="label">
            Soft Skills:
          </div>
          <ul>
            { education.skills && education.skills.filter(skill => skill.type === "soft").map((skill, index) => {
                return <Tag key={`soft-${index}`}>{skill.name}</Tag>
              })
            }
          </ul>
        </div>
        </div>
        <div className="skills tasks">
          <div className="label">
            Tasks:
          </div>
          <ul>
            { education.tasks && education.tasks.map((task, index) => {
                return <li key={`task-${index}`}>{task.name}</li>
              })
            }
          </ul>
        </div>
        <div className="languages">
          <div className="left">
            <div className="label">
              Certifications:
            </div>
            <ul>
              {education.certifications && education.certifications.map((certification, index) => {
                  return <Tag key={`certification-${index}`}>{certification.name}</Tag>
                })
              }
            </ul>
          </div>
          <div className="right">
            <div className="label">
              Courses:
            </div>
            <ul>
              {education.courses && education.courses.map((course, index) => {
                  return <Tag key={`course-${index}`}>{course.name}</Tag>
                })
              }
            </ul>
          </div>
        </div>
        <div className="languages">
          <div className="left">
            <div className="label">
              Languages:
            </div>
            <ul>
              {education.languages && education.languages.map((lang, index) => {
                  return <Tag key={`lang-${index}`}>{lang.name}</Tag>
                })
              }
            </ul>
          </div>
          <div className="right">
            <div className="label">
              Interests:
            </div>
            <ul>
              {education.interests && education.interests.map((int, index) => {
                  return <Tag key={`int-${index}`}>{int.name}</Tag>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </Timeline>
  )
}

export default Education